import { LoginPage } from '@pages/auth/login.tsx';
import { createFileRoute } from '@tanstack/react-router';

export type AuthLoginRoute = {
  logout: boolean;
};

export const Route = createFileRoute('/auth/login')({
  component: () => <LoginPage />,
  validateSearch: (search): AuthLoginRoute => {
    return {
      logout: (search.logout as boolean) || false,
    };
  },
});
