import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/themes')({
  component: () => <div>Hello /admin/themes!</div>,
  meta: () => [
    {
      title: 'Admin | Themes',
    },
  ],
});
