import { LevelsPage } from '@pages/admin/levels/levels.tsx';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/levels')({
  component: LevelsPage,
  meta: () => [
    {
      title: 'Admin | Niveaux',
    },
  ],
});
