import { createRouter } from '@tanstack/react-router';
import { routeTree } from '../routeTree.gen.ts';

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  context: {
    isAuthenticated: undefined!,
  },
});
