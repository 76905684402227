import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { RoleGuard } from '../../components/role-guard/role-guard.tsx';

export const Route = createFileRoute('/app/admin')({
  component: () => (
    <RoleGuard roles={[UserRole.ADMIN]}>
      <Outlet />
    </RoleGuard>
  ),
});
