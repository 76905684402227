import { Code, ConnectError, Interceptor, Transport } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { useAuthStore } from '@stores/auth.store.ts';
import { isTokenExpired, isTokenExpiringSoon } from '../utils/token.ts';
import { AuthService } from '@buf/khiman_class-lib.connectrpc_es/proto/auth/v1/auth_connect';
import { createClient } from '@connectrpc/connect';
import { baseTransport } from './transport.config';

// Move the refresh token function here
const refreshAccessToken = async (currentRefreshToken: string) => {
  const client = createClient(AuthService, baseTransport);
  return await client.refreshToken({
    refreshToken: currentRefreshToken,
  });
};

export const useCustomTransport = (requiresAuth: boolean = false): Transport => {
  const { setTokens, clearTokens } = useAuthStore();

  if (!requiresAuth) {
    return baseTransport;
  }

  const refresh = async (): Promise<boolean> => {
    try {
      const currentTokens = useAuthStore.getState().tokens;
      if (!currentTokens?.refreshToken) {
        return false;
      }

      const newTokens = await refreshAccessToken(currentTokens.refreshToken);
      setTokens({
        accessToken: newTokens.accessToken,
        refreshToken: currentTokens.refreshToken,
      });
      return true;
    } catch {
      clearTokens();
      return false;
    }
  };

  const authenticator: Interceptor = (next) => async (request) => {
    // Get the latest tokens at the time of the request
    const currentTokens = useAuthStore.getState().tokens;

    if (!currentTokens?.accessToken) {
      clearTokens();
      throw new Error('No access token available');
    }

    // Check if token is expired or expiring soon
    if (isTokenExpired(currentTokens.accessToken)) {
      const refreshSuccess = await refresh();
      if (!refreshSuccess) {
        clearTokens();
        throw new Error('Session expired');
      }
      // Get the new tokens after refresh
      const newTokens = useAuthStore.getState().tokens;
      request.header.set('Authorization', `Bearer ${newTokens!.accessToken}`);
    } else if (isTokenExpiringSoon(currentTokens.accessToken)) {
      // Refresh token in background if it's expiring soon
      refresh().catch(() => {
        console.warn('Background token refresh failed');
      });
      // Use current token for this request
      request.header.set('Authorization', `Bearer ${currentTokens.accessToken}`);
    } else {
      // Use current token
      request.header.set('Authorization', `Bearer ${currentTokens.accessToken}`);
    }

    try {
      return await next(request);
    } catch (error: unknown) {
      if (error instanceof ConnectError) {
        if (error.code == Code.PermissionDenied || error.code == Code.Unauthenticated) {
          const refreshSuccess = await refresh();
          if (refreshSuccess) {
            // Get the new tokens after refresh and retry
            const newTokens = useAuthStore.getState().tokens;
            request.header.set('Authorization', `Bearer ${newTokens!.accessToken}`);
            return await next(request);
          }
          clearTokens();
        }
      }

      throw error;
    }
  };

  return createConnectTransport({
    baseUrl: import.meta.env.VITE_API_URL,
    credentials: 'include',
    interceptors: [authenticator],
    jsonOptions: {
      enumAsInteger: true,
    },
  });
};
