import { Page } from '@components/page/page.tsx';
import { Link, useSearch } from '@tanstack/react-router';
import { Anchor, Box, Center, Divider, Flex, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { LoginForm } from './login-form.tsx';

export const LoginPage = () => {
  const { logout } = useSearch({ from: '/auth/login' });
  console.log('logout', logout);

  return (
    <Page title="Connexion">
      <SimpleGrid mih="100vh" p="md" cols={{ base: 1, lg: 1 }}>
        <Flex direction="column" align="flex-start">
          <Center flex={1} w="100%">
            <Box maw="25rem" miw={350}>
              {/*<Logo size="3rem" display="block" c="var(--mantine-primary-color-filled)" mb="xl" />*/}
              <Stack gap="xl">
                <Stack>
                  <Title order={2}>Bienvenue!</Title>
                  <Text fz="sm" c="dimmed">
                    Veuillez vous connecter pour continuer.
                  </Text>
                </Stack>

                {/*<Group grow>*/}
                {/*  <Button leftSection={<GoogleIcon size="1rem" />} variant="outline" color="gray">*/}
                {/*    Connexion Google*/}
                {/*  </Button>*/}
                {/*  <Button leftSection={<XIcon size="1rem" />} variant="outline" color="gray">*/}
                {/*    Connexion X*/}
                {/*  </Button>*/}
                {/*</Group>*/}

                <Divider labelPosition="center" />

                <LoginForm />

                <Text fz="sm" c="dimmed">
                  Pas encore de compte?{' '}
                  <Anchor fz="inherit" component={Link} to={'/auth/register'}>
                    S'inscrire
                  </Anchor>
                </Text>
              </Stack>
            </Box>
          </Center>
        </Flex>
      </SimpleGrid>
    </Page>
  );
};
