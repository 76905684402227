import { useCallback } from 'react';
import { CreateUserRequestSchema } from '@api/dtos/user';
import {
  CreateUserRequest,
  UserRole,
} from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { useCreateUser } from '@hooks/services/user';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { rolesDict, transformRolesForSelect } from '@utils/roles';
import { Button, Drawer, Fieldset, Flex, Select, Switch, TextInput } from '@mantine/core';

interface UserCreateDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const UserCreateDrawer = (props: UserCreateDrawerProps) => {
  const { opened, onClose } = props;
  const mutateUser = useCreateUser();

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: UserRole.STUDENT,
    authorized: false,
    password: '',
  };

  const { Field, handleSubmit, reset, Subscribe } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CreateUserRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const data = new CreateUserRequest(value);

      mutateUser.mutate(data, {
        onSuccess: () => {
          reset();
          onClose();
        },
      });
    },
  });

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Informations personnelles" variant="filled" w="100%">
            <Field
              name="firstName"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Prénom"
                  placeholder="Prénom"
                  autoComplete="name"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="lastName"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  autoComplete="family-name"
                  placeholder="Nom"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="email"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Email"
                  autoComplete="email"
                  placeholder="Email"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="password"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Mot de passe"
                  type="password"
                  placeholder="Mot de passe"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Fieldset legend="Paramètres administratifs" variant="filled" w="100%">
            <Field
              name="role"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  data={transformRolesForSelect(rolesDict)}
                  value={state.value?.toString() ?? ''}
                  onChange={(value) => handleChange(Number(value) as UserRole)}
                  onBlur={handleBlur}
                  label="Role"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="authorized"
              children={({ state, handleChange, handleBlur }) => (
                <Switch
                  mt="md"
                  checked={state.value}
                  onChange={(e) => handleChange(e.target.checked)}
                  onBlur={handleBlur}
                  label="Autorisé"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.isValid]}
            children={([isValid]) => (
              <Button
                disabled={!isValid || mutateUser.isPending}
                loading={mutateUser.isPending}
                type="submit"
              >
                Créer
              </Button>
            )}
          />
        </Flex>
      </form>
    </Drawer>
  );
};
