import { createFileRoute } from '@tanstack/react-router';
import { UsersPage } from '@pages/admin/users/users.tsx';

export const Route = createFileRoute('/app/admin/users')({
  component: UsersPage,
  meta: () => [
    {
      title: 'Admin | Utilisateurs',
    },
  ],
});
