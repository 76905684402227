import { create } from 'zustand';

interface AppShellState {
  isNavbarCollapsed: boolean;
  toggleNavbarCollapsed: () => void;
}

export const useAppShellStore = create<AppShellState>((set) => ({
  isNavbarCollapsed: false,
  toggleNavbarCollapsed: () => set((state) => ({ isNavbarCollapsed: !state.isNavbarCollapsed })),
}));
