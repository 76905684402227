import { useCallback } from 'react';
import { CreateLevelRequestSchema } from '@api/dtos/level';
import { useCreateLevel } from '@hooks/services/level';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Button, Drawer, Fieldset, Flex, TextInput } from '@mantine/core';

interface LevelDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const LevelCreateDrawer = (props: LevelDrawerProps) => {
  const { opened, onClose } = props;
  const mutateLevel = useCreateLevel();

  const defaultValues = {
    name: '',
    description: '',
    levelId: '',
  };

  const { Field, handleSubmit, reset, Subscribe } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CreateLevelRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      mutateLevel.mutate(value, {
        onSuccess: () => {
          handleClose();
        },
      });
    },
  });

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Niveau" variant="filled" w="100%">
            <Field
              name="name"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  placeholder="Description"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.isDirty]}
            children={([isDirty]) => (
              <Button
                disabled={!isDirty || mutateLevel.isPending}
                loading={mutateLevel.isPending}
                type="submit"
              >
                Créer
              </Button>
            )}
          />
        </Flex>
      </form>
    </Drawer>
  );
};
