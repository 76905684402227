import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/student/courses/search')({
  component: () => <div>Hello /student/courses/search!</div>,
  meta: () => [
    {
      title: 'Étudiant | Recherche de cours',
    },
  ],
});
