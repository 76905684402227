import { AuthLoginRoute } from '@routes/auth/login.tsx';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/auth')({
  validateSearch: (search) => search as AuthLoginRoute,
  beforeLoad: ({ context, search }) => {
    if (context.isAuthenticated && !search.logout) {
      throw redirect({
        to: '/app',
      });
    }
  },
  component: UnauthenticatedApp,
});

function UnauthenticatedApp() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
