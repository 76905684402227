import { LoginRequest } from '@buf/khiman_class-lib.bufbuild_es/proto/auth/v1/auth_pb';
import { SignupRequest } from '@buf/khiman_class-lib.bufbuild_es/proto/signup/v1/signup_pb';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { validateProtoSchema } from '@utils/zod.ts';
import { z } from 'zod';

export const LoginRequestSchema = validateProtoSchema<LoginRequest>(
  z.object({
    username: z
      .string({ invalid_type_error: "Le nom d'utilisateur est obligatoire" })
      .min(1, "Le nom d'utilisateur est obligatoire"),
    password: z
      .string({ invalid_type_error: 'Le mot de passe est obligatoire' })
      .min(1, 'Le mot de passe est obligatoire'),
  })
).validateType();

export const SignupRequestSchema = validateProtoSchema<SignupRequest>(
  z
    .object({
      email: z
        .string({ invalid_type_error: "L'email est obligatoire" })
        .min(1, "L'email est obligatoire"),
      password: z
        .string({ invalid_type_error: 'Le mot de passe est obligatoire' })
        .min(1, 'Le mot de passe est obligatoire'),
      confirmPassword: z
        .string({ invalid_type_error: 'La confirmation du mot de passe est obligatoire' })
        .min(1, 'La confirmation du mot de passe est obligatoire'),
      firstName: z
        .string({ invalid_type_error: 'Le prénom est obligatoire' })
        .min(1, 'Le prénom est obligatoire'),
      lastName: z
        .string({ invalid_type_error: 'Le nom est obligatoire' })
        .min(1, 'Le nom est obligatoire'),
      role: z.nativeEnum(UserRole, { invalid_type_error: 'Role is required' }),
    })
    .superRefine(({ confirmPassword, password }, ctx) => {
      if (password !== confirmPassword) {
        return ctx.addIssue({
          code: 'custom',
          message: 'Les mots de passe ne correspondent pas',
          path: ['confirmPassword'],
        });
      }
    })
).validateType();
