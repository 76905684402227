import { useMemo } from 'react';
import { ActiveUserRole, rolesDict } from '@utils/roles.ts';
import { Badge } from '@mantine/core';

export type RoleTagProps = {
  role: ActiveUserRole;
};

export const RoleTag = ({ role }: RoleTagProps) => {
  return useMemo(() => {
    return (
      <Badge variant="light" color={rolesDict[role].color}>
        {rolesDict[role].text}
      </Badge>
    );
  }, [role]);
};
