import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/courses')({
  component: () => <div>Hello /admin/courses!</div>,
  meta: () => [
    {
      title: 'Admin | Cours',
    },
  ],
});
