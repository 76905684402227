import React from 'react';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { useMe } from '@hooks/services/auth.tsx';
import { useUser } from '@hooks/services/user.tsx';
import { RoleConfig, rolesDict } from '@utils/roles.ts';
import { Avatar, Flex, Text } from '@mantine/core';
import { ToggleColorScheme } from '../toggle-color-scheme/toggle-color-scheme.tsx';

const Header = () => {
  const userId = useMe();
  const { data, isError } = useUser({ input: { id: userId } });

  if (isError) {
    // TODO: handle auth error
    return null;
  }

  return (
    <Flex direction="row" w="100%" align="center" justify="space-between">
      <div>
        <Text size="xl" fw={500}>
          Bonjour, {data?.user?.firstName} !
        </Text>
        <Text size="sm" c="dimmed">
          Bienvenue sur class-lib.fr
        </Text>
      </div>
      <Flex direction="row" gap="md" align="center">
        <ToggleColorScheme />
        <Flex gap="sm">
          <Avatar color="cyan" radius="xl">
            {data?.user?.firstName?.charAt(0)}
            {data?.user?.lastName?.charAt(0)}
          </Avatar>
          <Flex direction="column">
            <Text size="sm">
              {data?.user?.firstName} {data?.user?.lastName}
            </Text>
            <Text size="xs" c="dimmed">
              {(rolesDict[data?.user?.role || UserRole.STUDENT] as RoleConfig)?.text}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(Header);
