import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/settings')({
  component: () => <div>Hello /admin/settings!</div>,
  meta: () => [
    {
      title: 'Admin | Paramètres',
    },
  ],
});
