import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';

export interface RoleConfig {
  text: string;
  color: string;
}

// Create a type that excludes UNSPECIFIED from UserRole
export type ActiveUserRole = Exclude<UserRole, UserRole.UNSPECIFIED>;

// Use the new type for the dictionary
export type RolesDict = {
  [key in ActiveUserRole]: RoleConfig;
};

// The actual dictionary implementation
export const rolesDict: RolesDict = {
  [UserRole.ADMIN]: { text: 'Admin', color: 'red' },
  [UserRole.STUDENT]: { text: 'Étudiant', color: 'green' },
  [UserRole.TEACHER]: { text: 'Enseignant', color: 'purple' },
};

export const transformRolesForSelect = (roles: RolesDict) => {
  return Object.entries(roles).map(([value, config]) => ({
    value: value,
    label: config.text,
    color: config.color,
  }));
};
