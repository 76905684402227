import { FieldMask, PartialMessage } from '@bufbuild/protobuf';

type Primitive = string | number | boolean | null | undefined;

interface FieldMeta {
  isDirty: boolean;
}

// Helper type to get all possible deep paths in an object type
type DeepKeys<T> = T extends Primitive
  ? never
  : T extends Array<never>
    ? never
    : T extends object
      ? {
          [K in keyof T]: K extends string | number
            ? T[K] extends object
              ? K | `${K}.${DeepKeys<T[K]>}`
              : K
            : never;
        }[keyof T]
      : never;

// Helper type for the field meta store
type FieldMetaStore<T> = Record<DeepKeys<T>, FieldMeta>;

/**
 * Converts camelCase to snake_case
 * @param str String in camelCase
 * @returns String in snake_case
 */
const toSnakeCase = (str: string): string => {
  return str
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/^_/, '');
};

/**
 * Converts a path with possible camelCase segments to snake_case
 * @param path Dot-notation path
 * @returns Path with segments converted to snake_case
 */
const convertPathToSnakeCase = (path: string): string => {
  return path
    .split('.')
    .map((segment) => toSnakeCase(segment))
    .join('.');
};

/**
 * Converts an array of dot-notation paths to a FieldMask array
 * @param paths Array of dot-notation paths
 * @returns Array of paths in FieldMask format with snake_case
 */
export const convertToFieldMask = (paths: string[]): string[] => {
  return paths.map((path) => path.replace(/\[\d+\]/g, '.*')).map(convertPathToSnakeCase);
};

/**
 * Gets all dirty field paths from the field meta store
 * @param fieldMeta Field meta store containing touch state for all fields
 * @returns Array of dirty field paths
 */
export const getDirtyFieldPaths = <T>(fieldMeta: FieldMetaStore<T>): string[] => {
  return (
    Object.entries(fieldMeta)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, meta]) => (meta as FieldMeta).isDirty)
      .map(([path]) => path)
  );
};

/**
 * Generates a FieldMask from dirty fields in the form
 * @param fieldMeta Field meta store containing touch state for all fields
 * @returns FieldMask object with dirty paths in snake_case
 */
export const generateDirtyFieldMask = <T>(
  fieldMeta: FieldMetaStore<T>
): PartialMessage<FieldMask> => {
  const dirtyPaths = getDirtyFieldPaths(fieldMeta);
  const values = convertToFieldMask(dirtyPaths);

  return { paths: values };
};
