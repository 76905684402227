import {
  CreateSubjectRequest,
  UpdateSubjectOperation,
} from '@buf/khiman_class-lib.bufbuild_es/proto/subject/v1/subject_pb';
import { validateProtoSchema } from '@utils/zod.ts';
import { z } from 'zod';

export const UpdateSubjectRequestSchema = validateProtoSchema<UpdateSubjectOperation>(
  z.object({
    id: z.string({ invalid_type_error: 'Id is required' }).min(1, 'Id is required'),
    name: z.string({ invalid_type_error: 'Name is required' }).min(1, 'Name is required'),
    description: z
      .string({ invalid_type_error: 'Description is required' })
      .min(1, 'Description is required'),
    levelId: z.string({ invalid_type_error: 'LevelId is required' }).min(1, 'LevelId is required'),
  })
).validateType();

export const CreateSubjectRequestSchema = validateProtoSchema<CreateSubjectRequest>(
  z.object({
    name: z.string({ invalid_type_error: 'Name is required' }).min(1, 'Name is required'),
    description: z
      .string({ invalid_type_error: 'Description is required' })
      .min(1, 'Description is required'),
    levelId: z.string({ invalid_type_error: 'LevelId is required' }).min(1, 'LevelId is required'),
  })
).validateType();
