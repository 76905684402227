import { useEffect } from 'react';
import { LoginRequestSchema } from '@api/dtos/auth.ts';
import { useLogin } from '@hooks/services/auth.tsx';
import { useAuthStore } from '@stores/auth.store.ts';
import { useForm } from '@tanstack/react-form';
import { useNavigate } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Button, Stack, TextInput } from '@mantine/core';

export const LoginForm = () => {
  const { loginWithCredentials, isPending } = useLogin();
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate({ from: '/auth/login' });

  const { Field, handleSubmit } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      username: '',
      password: '',
    },
    validators: {
      onSubmit: LoginRequestSchema,
    },
    onSubmit: async ({ value }) => {
      await loginWithCredentials(value);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate({ to: '/app' });
    }
  }, [navigate, isAuthenticated]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Stack>
        <Field
          name="username"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Nom d'utilisateur"
              placeholder="Nom d'utilisateur"
              autoComplete="username"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Field
          name="password"
          children={({ state, handleChange, handleBlur }) => (
            <TextInput
              defaultValue={state.value}
              onChange={(e) => handleChange(e.target.value)}
              onBlur={handleBlur}
              label="Mot de passe"
              type="password"
              autoComplete="current-password"
              placeholder="Mot de passe"
              error={state.meta.errors ? state.meta.errors.join(',') : null}
            />
          )}
        />
        <Button type="submit" loading={isPending}>
          Connexion
        </Button>
      </Stack>
    </form>
  );
};
