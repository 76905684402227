import React, { Suspense } from 'react';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { AppShell } from '@mantine/core';
import { useAppShellStore } from '../stores/appshell.store.ts';

interface RouterContext {
  isAuthenticated: boolean;
  meta?: {
    title: string;
  };
}

// Lazy load the router devtools in development
const TanStackRouterDevtools =
  import.meta.env.MODE === 'production'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

// Lazy load the ReactQueryDevtools
const ReactQueryDevtools =
  import.meta.env.MODE === 'production'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        }))
      );

// Create a root route that wraps the entire app in an AppShell
export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <Appshell />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
      <Suspense>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </Suspense>
    </>
  ),
});

// AppShell is a layout component that provides a header, navbar, and main content area
const Appshell = () => {
  const isCollapsed = useAppShellStore((state) => state.isNavbarCollapsed);

  return (
    <AppShell
      layout="alt"
      header={{ height: 80 }}
      navbar={{
        width: 250,
        breakpoint: 'sm',
        collapsed: { mobile: isCollapsed, desktop: isCollapsed },
      }}
      padding="md"
    >
      <Outlet />
    </AppShell>
  );
};
