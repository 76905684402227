import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { UpdateUserRequestSchema } from '@api/dtos/user';
import {
  UpdateUserOperation,
  UserRole,
} from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { useUpdateUser, useUser } from '@hooks/services/user';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { generateDirtyFieldMask } from '@utils/field-mask';
import { rolesDict, transformRolesForSelect } from '@utils/roles';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Fieldset,
  Flex,
  LoadingOverlay,
  Select,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';

interface UserEditDrawerProps {
  opened: boolean;
  onClose: () => void;
  userId?: string;
}

const getInitials = (firstName?: string, lastName?: string): string => {
  const first = firstName?.charAt(0) || '';
  const last = lastName?.charAt(0) || '';
  return `${first}${last}`.toUpperCase();
};

export const UserEditDrawer = (props: UserEditDrawerProps) => {
  const { opened, onClose, userId } = props;
  const { data, isLoading, isFetching } = useUser({ input: { id: userId } });
  const mutateUser = useUpdateUser();

  const defaultValues = useMemo(
    () => ({
      firstName: data?.user?.firstName ?? '',
      lastName: data?.user?.lastName ?? '',
      email: data?.user?.email ?? '',
      role: data?.user?.role ?? UserRole.STUDENT,
      authorized: data?.user?.authorized ?? false,
      id: data?.user?.id ?? '',
    }),
    [data?.user]
  );

  const { Field, handleSubmit, reset, Subscribe, useStore } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: UpdateUserRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      const touchedFields = generateDirtyFieldMask(fields);
      console.log('Dirty fields:', touchedFields);
      const data = new UpdateUserOperation(value);

      mutateUser.mutate(
        {
          user: data,
          updateMask: touchedFields,
        },
        {
          onSuccess: () => {
            reset();
            onClose();
          },
        }
      );
    },
  });

  const fields = useStore((state) => state.fieldMeta);

  useEffect(() => {
    if (opened && data?.user) {
      reset();
    }
  }, [opened, data?.user, defaultValues, reset]);

  const userInitials = useMemo(
    () => getInitials(data?.user?.firstName, data?.user?.lastName),
    [data?.user?.firstName, data?.user?.lastName]
  );

  const isLoaderVisible = isLoading || isFetching;

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <LoadingOverlay
        visible={isLoaderVisible}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Flex align="center" w="100%" justify="center">
        <Avatar color="cyan" radius="xl" size="160">
          {userInitials}
        </Avatar>
      </Flex>
      <Flex mt="md" direction="row" align="center" gap="md" justify="center">
        <Flex direction="column" gap="1" align="center">
          <Text>
            {data?.user?.firstName} {data?.user?.lastName}
          </Text>
          <Text c="dimmed">{data?.user?.email}</Text>
        </Flex>
      </Flex>
      <Divider mt="md" mb="md" />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Informations personnelles" variant="filled" w="100%">
            <Field
              name="firstName"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Prénom"
                  placeholder="Prénom"
                  autoComplete="name"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="lastName"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  autoComplete="family-name"
                  placeholder="Nom"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="email"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Email"
                  autoComplete="email"
                  placeholder="Email"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Fieldset legend="Paramètres administratifs" variant="filled" w="100%">
            <Field
              name="role"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  data={transformRolesForSelect(rolesDict)}
                  value={state.value?.toString() ?? ''}
                  onChange={(value) => handleChange(Number(value) as UserRole)}
                  onBlur={handleBlur}
                  label="Role"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="authorized"
              children={({ state, handleChange, handleBlur }) => (
                <Switch
                  mt="md"
                  checked={state.value}
                  onChange={(e) => handleChange(e.target.checked)}
                  onBlur={handleBlur}
                  label="Autorisé"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.isTouched]}
            children={([isTouched]) => (
              <Button
                disabled={!isTouched || mutateUser.isPending}
                loading={mutateUser.isPending}
                type="submit"
              >
                Enregistrer
              </Button>
            )}
          />
          <Fieldset legend="Informatons additionnelles" variant="filled" w="100%">
            <TextInput value={data?.user?.id ?? ''} label="ID" disabled placeholder="ID" />
            <TextInput
              value={dayjs((data?.user?.lastLogin as Timestamp)?.toDate())?.format(
                'DD/MM/YYYY HH:mm:ss'
              )}
              label="Dernière connexion"
              disabled
              placeholder="Dernière connexion"
            />
            <TextInput
              value={dayjs((data?.user?.updatedAt as Timestamp)?.toDate())?.format(
                'DD/MM/YYYY HH:mm:ss'
              )}
              label="Dernière mise à jour"
              disabled
              placeholder="Dernière mise à jour"
            />
            <TextInput
              value={dayjs((data?.user?.createdAt as Timestamp)?.toDate())?.format(
                'DD/MM/YYYY HH:mm:ss'
              )}
              label="Date de création"
              disabled
              placeholder="Date de création"
            />
          </Fieldset>
        </Flex>
      </form>
    </Drawer>
  );
};
