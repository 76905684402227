import { useState } from 'react';
import {
  MRT_ColumnDef,
  MRT_ColumnFilterFnsState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowData,
  MRT_SortingState,
} from 'mantine-react-table';
import { DataTableState } from './types.ts';

export const useDataTableState = <TData extends MRT_RowData>(
  columns: MRT_ColumnDef<TData>[]
): DataTableState => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    Object.fromEntries(
      columns.filter((col) => col.accessorKey).map(({ accessorKey }) => [accessorKey, 'contains'])
    )
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  return {
    columnFilters,
    columnFilterFns,
    globalFilter,
    sorting,
    pagination,
    setColumnFilters,
    setColumnFilterFns,
    setGlobalFilter,
    setSorting,
    setPagination,
  };
};
