import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import {
  ListUsersResponse,
  User,
} from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { DataTable } from '@components/data-table/data-table';
import { RowActions } from '@components/data-table/row-actions';
import { RowActionProps } from '@components/data-table/types';
import { RoleTag } from '@components/role-tag/role-tag';
import { ConnectError } from '@connectrpc/connect';
import { useListUsers } from '@hooks/services/user';
import { UserCreateDrawer } from '@pages/admin/users/components/user-create-drawer.tsx';
import { IconPlus } from '@tabler/icons-react';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Badge, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { UserEditDrawer } from './components/user-edit-drawer.tsx';
import { ActiveUserRole } from '@utils/roles.ts';

export const UsersPage = () => {
  const [selectedUserId, setSelectedUser] = useState<string | undefined>();
  const [editDrawerOpen, { open: openEditDrawer, close: closeEditDrawer }] = useDisclosure(false);
  const [createCreateDrawerOpen, { open: openCreateDrawer, close: closeCreateDrawer }] =
    useDisclosure(false);

  const handleEdit = useCallback(
    (userId: string) => {
      if (userId) {
        setSelectedUser(userId);
        openEditDrawer();
      }
    },
    [openEditDrawer]
  );

  const handleDelete = useCallback(async (userId: string) => {
    // Implement delete functionality here
    console.log('Delete user:', userId);
  }, []);

  const handleCreate = () => {
    openCreateDrawer();
  };

  const handleCloseEditDrawer = () => {
    closeEditDrawer();
  };

  const handleCloseCreateDrawer = () => {
    closeCreateDrawer();
  };

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'Prénom',
      },
      {
        accessorKey: 'lastName',
        header: 'Nom',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'emailVerified',
        header: 'Email vérifié',
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Badge variant="dot" color="blue">
              Oui
            </Badge>
          ) : (
            <Badge variant="dot" color="red">
              Non
            </Badge>
          ),
      },
      {
        accessorKey: 'role',
        header: 'Role',
        Cell: ({ cell }) => <RoleTag role={cell.getValue() as ActiveUserRole} />,
      },
      {
        accessorKey: 'authorized',
        header: 'Autorisé',
        Cell: ({ cell }) =>
          cell.getValue() ? (
            <Badge variant="dot" color="blue">
              Oui
            </Badge>
          ) : (
            <Badge variant="dot" color="red">
              Non
            </Badge>
          ),
      },
      {
        accessorKey: 'createdAt',
        header: 'Créé le',
        Cell: ({ cell }) =>
          dayjs((cell.getValue() as Timestamp).toDate()).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        accessorKey: 'updatedAt',
        header: 'Mis à jour le',
        Cell: ({ cell }) =>
          dayjs((cell.getValue() as Timestamp).toDate()).format('DD/MM/YYYY HH:mm:ss'),
      },
    ],
    []
  );

  const queryParams = useMemo(() => ({}), []);

  const customRowActions = useCallback(
    (props: RowActionProps<User>) => (
      <RowActions<User>
        {...props}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    ),
    [handleEdit, handleDelete]
  );

  const customTableActions = (
    <Button leftSection={<IconPlus />} onClick={handleCreate}>
      Ajouter un utilisateur
    </Button>
  );

  const responseAdapter = useCallback(
    (response: ListUsersResponse | undefined) => ({
      items: response?.users ?? [],
      total: response?.total ?? 0,
    }),
    []
  );

  return (
    <>
      <UserEditDrawer
        opened={editDrawerOpen}
        onClose={handleCloseEditDrawer}
        userId={selectedUserId}
      />
      <UserCreateDrawer opened={createCreateDrawerOpen} onClose={handleCloseCreateDrawer} />
      <DataTable<User, ListUsersResponse, ConnectError>
        columns={columns}
        useQueryHook={useListUsers}
        queryParams={queryParams}
        enableFilterModes={false}
        enableCustomRowActions={true}
        customRowActions={customRowActions}
        responseAdapter={responseAdapter}
        customActions={customTableActions}
        errorAdapter={(error) => new Error(error.message)}
      />
    </>
  );
};
