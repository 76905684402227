import { LoginRequest } from '@buf/khiman_class-lib.bufbuild_es/proto/auth/v1/auth_pb';
import { login } from '@buf/khiman_class-lib.connectrpc_query-es/proto/auth/v1/auth-AuthService_connectquery';
import { signup } from '@buf/khiman_class-lib.connectrpc_query-es/proto/signup/v1/signup-SignupService_connectquery';
import type { PartialMessage } from '@bufbuild/protobuf';
import { useMutation } from '@connectrpc/connect-query';
import { createConnectTransport } from '@connectrpc/connect-web';
import { useAuthStore } from '@stores/auth.store.ts';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { notifications } from '@mantine/notifications';

// Login Hook
export const useLogin = () => {
  const { setTokens } = useAuthStore();

  const mutation = useMutation(login, {
    transport: baseTransport,
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: 'Nom d\'utilisateur ou mot de passe incorrect',
        message: "Détails de l'erreur: " + error.message,
      })
    },
    onSuccess: () => {
      notifications.show({
        color: 'green',
        title: 'Connexion réussie',
        message: 'Vous êtes maintenant connecté(e)',
      });
    }
  });

  const loginWithCredentials = async (credentials: PartialMessage<LoginRequest>) => {
    const response = await mutation.mutateAsync({
      username: credentials.username,
      password: credentials.password,
    });

    if (response.accessToken && response.refreshToken) {
      setTokens({
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
      });
    }

    return response;
  };

  return {
    loginWithCredentials,
    isPending: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};

const baseTransport = createConnectTransport({
  baseUrl: import.meta.env.VITE_API_URL,
  jsonOptions: {
    enumAsInteger: true,
  },
});

// Logout Helper
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useLogout = (router) => {
  const { clearTokens } = useAuthStore();

  const handleLogout = () => {
    router.navigate({ to: '/auth/login', search: { logout: true } });
    clearTokens();
    router.invalidate();
  };

  return {
    logout: handleLogout,
  };
};

interface CustomJwt extends JwtPayload {
  user_id: string;
}

export const useMe = () => {
  const accessToken = useAuthStore((state) => state.tokens?.accessToken);
  let myId: string | undefined;

  try {
    const decoded = jwtDecode(accessToken || '') as CustomJwt;
    myId = decoded?.user_id;
  } catch (e) {
    console.log('Error decoding token', e);
  }

  return myId;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const useSignup = (router) => {
  return useMutation(signup, {
    transport: baseTransport,
    retry: false,
    onSuccess: () => {
      router.navigate({ to: '/auth/login', search: { signup: true } });
    },
  });
};
