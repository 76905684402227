/* eslint-disable @typescript-eslint/no-unused-vars */

import { Message } from '@bufbuild/protobuf';
import { z } from 'zod';

// Helper types to make the code more readable
type IsFunction<T> = T extends (...args: never[]) => unknown ? true : false;
type IsMessageKey<K extends PropertyKey, T extends Message<T>> = K extends keyof Message<T>
  ? true
  : false;

// Extracts only data field keys from a Protobuf message
type DataFieldKeys<T extends Message<T>> = {
  [K in keyof T]: IsFunction<T[K]> extends true
    ? never
    : IsMessageKey<K, T> extends true
      ? never
      : K;
}[keyof T];

// The final type that extracts data fields from a Protobuf message
type ProtoDataFields<T extends Message<T>> = Pick<T, DataFieldKeys<T>>;
type TypedZodSchema<T> = z.ZodType<T, z.ZodTypeDef, T>;

// Helper function to type-check Zod schema against Protobuf message
export function validateProtoSchema<
  TProto extends Message<TProto>,
  TSchema extends TypedZodSchema<ProtoDataFields<TProto>> = TypedZodSchema<ProtoDataFields<TProto>>,
>(schema: TSchema) {
  return {
    schema,
    validateType: () => {
      type SchemaType = z.infer<typeof schema>;
      type ProtoFields = ProtoDataFields<TProto>;

      // Verify types match exactly at compile time
      type ExtraSchemaFields = Omit<SchemaType, keyof ProtoFields>;
      type MissingSchemaFields = Omit<ProtoFields, keyof SchemaType>;
      // eslint-disable-next-line
      // @ts-expect-error
      type TypesMatch = [ExtraSchemaFields, MissingSchemaFields] extends [object, object]
        ? true
        : { error: 'Schema fields do not match Protobuf fields exactly' };

      // Return the schema for chaining
      return schema;
    },
  };
}
