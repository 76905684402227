import { Page } from '@components/page/page.tsx';
import { RegisterForm } from '@pages/auth/register-form.tsx';
import { Link } from '@tanstack/react-router';
import {
  Anchor,
  Box,
  Center,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';

export const RegisterPage = () => {
  return (
    <Page title="Inscription">
      <SimpleGrid mih="100vh" p="md" cols={{ base: 1, lg: 1 }}>
        <Flex direction="column" align="flex-start">
          <Center flex={1} w="100%">
            <Box maw="25rem" miw={350}>
              {/*<Logo size="3rem" display="block" c="var(--mantine-primary-color-filled)" mb="xl" />*/}
              <Stack gap="xl">
                <Stack>
                  <Title order={2}>Je suis un ...</Title>
                </Stack>

                <RegisterForm />

                <Text fz="sm" c="dimmed">
                  Déjà un compte?{' '}
                  <Anchor fz="inherit" component={Link} to={'/auth/login'}>
                    Se connecter
                  </Anchor>
                </Text>
              </Stack>
            </Box>
          </Center>
        </Flex>
      </SimpleGrid>
    </Page>
  );
};
