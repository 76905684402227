import { useCallback, useMemo, useState } from 'react';
import {
  ListSubjectsResponse,
  Subject,
} from '@buf/khiman_class-lib.bufbuild_es/proto/subject/v1/subject_pb';
import { LevelCell } from '@components/cells/level-cell.tsx';
import { DataTable } from '@components/data-table/data-table';
import { RowActions } from '@components/data-table/row-actions';
import { RowActionProps } from '@components/data-table/types';
import { ConnectError } from '@connectrpc/connect';
import { useListSubjects } from '@hooks/services/subject.tsx';
import { SubjectCreateDrawer } from '@pages/admin/subjects/components/subject-create-drawer.tsx';
import { IconPlus } from '@tabler/icons-react';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { SubjectEditDrawer } from './components/subject-edit-drawer.tsx';

export const SubjectsPage = () => {
  const [selectedSubjectId, setSelectedSubject] = useState<string | undefined>();
  const [editSubjectDrawerOpen, { open: openEditSubjectDrawer, close: closeEditSubjectDrawer }] =
    useDisclosure(false);
  const [
    createSubjectDrawerOpen,
    { open: openCreateSubjectDrawer, close: closeCreateSubjectDrawer },
  ] = useDisclosure(false);

  const handleEdit = useCallback(
    (subjectId: string) => {
      if (subjectId) {
        setSelectedSubject(subjectId);
        openEditSubjectDrawer();
      }
    },
    [openEditSubjectDrawer]
  );

  const customTableActions = (
    <Button leftSection={<IconPlus />} onClick={openCreateSubjectDrawer}>
      Ajouter un sujet
    </Button>
  );

  const handleDelete = useCallback(async (subjectId: string) => {
    // Implement delete functionality here
    console.log('Delete subject:', subjectId);
  }, []);

  const columns = useMemo<MRT_ColumnDef<Subject>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Nom',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'levelId',
        header: 'Niveau',
        Cell: ({ cell }) => <LevelCell levelId={cell.getValue<string>()} />,
      },
    ],
    []
  );

  const queryParams = useMemo(() => ({}), []);

  const customRowActions = useCallback(
    (props: RowActionProps<Subject>) => (
      <RowActions<Subject> {...props} onEdit={handleEdit} onDelete={handleDelete} />
    ),
    [handleEdit, handleDelete]
  );

  const responseAdapter = useCallback(
    (response: ListSubjectsResponse | undefined) => ({
      items: response?.subjects ?? [],
      total: response?.total ?? 0,
    }),
    []
  );

  return (
    <>
      <SubjectEditDrawer
        opened={editSubjectDrawerOpen}
        onClose={closeEditSubjectDrawer}
        subjectId={selectedSubjectId}
      />
      <SubjectCreateDrawer opened={createSubjectDrawerOpen} onClose={closeCreateSubjectDrawer} />
      <DataTable<Subject, ListSubjectsResponse, ConnectError>
        columns={columns}
        useQueryHook={useListSubjects}
        queryParams={queryParams}
        enableCustomRowActions={true}
        enableFilterModes={false}
        customRowActions={customRowActions}
        customActions={customTableActions}
        responseAdapter={responseAdapter}
        errorAdapter={(error) => new Error(error.message)}
      />
    </>
  );
};
