import { Text } from '@mantine/core';

export function NavbarLogo() {
  return (
    <div>
      <Text size="xl" fw={700}>
        class-lib.fr
      </Text>
    </div>
  );
}

export default NavbarLogo;
