import { IconMoon, IconSun } from '@tabler/icons-react';
import { ActionIcon, useMantineColorScheme } from '@mantine/core';

export const ToggleColorScheme = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <ActionIcon
      onClick={toggleColorScheme}
      variant="subtle"
      aria-label="Changer le thème"
      size={34}
    >
      {colorScheme === 'dark' ? <IconSun stroke={1} /> : <IconMoon stroke={1} />}
    </ActionIcon>
  );
};
