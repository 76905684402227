import { useLevel } from '@hooks/services/level.tsx';
import { Skeleton, Text } from '@mantine/core';

interface LevelCellProps {
  levelId: string;
}

export const LevelCell = ({ levelId }: LevelCellProps) => {
  const {
    data: level,
    isLoading,
    error,
  } = useLevel({
    input: { id: levelId },
  });

  if (isLoading) {
    return <Skeleton height={20} />;
  }

  if (error || !level) {
    return (
      <Text c="red" fz={14}>
        Error loading level
      </Text>
    );
  }

  return <Text fz={14}>{level.level?.name}</Text>;
};
