import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/admin/billing')({
  component: () => <div>Hello /admin/billing!</div>,
  meta: () => [
    {
      title: 'Admin | Facturation',
    },
  ],
});
