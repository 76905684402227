import { memo } from 'react';
import classes from './navbar.module.scss';
import { useLogout } from '@hooks/services/auth.tsx';
import { IconLogout } from '@tabler/icons-react';
import { useRouter } from '@tanstack/react-router';
import { Button } from '@mantine/core';

function NavbarFooter() {
  const router = useRouter();
  const { logout } = useLogout(router);

  return (
    <div>
      <Button
        variant="subtle"
        fullWidth
        className={classes.sectionLink}
        leftSection={<IconLogout />}
        onClick={logout}
      >
        Déconnexion
      </Button>
    </div>
  );
}

export default memo(NavbarFooter);
