import { StrictMode, useMemo } from 'react';
import type { Transport } from '@connectrpc/connect';
import { TransportProvider } from '@connectrpc/connect-query';
import { createConnectTransport } from '@connectrpc/connect-web';
import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { theme } from './theme';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/notifications/styles.css';

import { queryClient } from '@utils/queryclient.ts';
import { HelmetProvider } from 'react-helmet-async';
import { NavigationProgress } from '@mantine/nprogress';
import { useAuthStore } from './stores/auth.store.ts';
import { router } from './utils/router.ts';

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default function App({ transport }: { transport?: Transport }) {
  const finalTransport =
    transport ??
    createConnectTransport({
      baseUrl: 'http://localhost:8080',
      jsonOptions: {
        enumAsInteger: true,
      },
    });

  const { isAuthenticated } = useAuthStore();

  const notifications = useMemo(() => <Notifications position="bottom-center" />, []);
  const navigationProgress = useMemo(() => <NavigationProgress />, []);

  return (
    <HelmetProvider>
      <TransportProvider transport={finalTransport}>
        <QueryClientProvider client={queryClient}>
          <MantineProvider theme={theme} defaultColorScheme="auto">
            {notifications}
            {navigationProgress}
            <RouterProvider router={router} context={{ isAuthenticated }} />
          </MantineProvider>
        </QueryClientProvider>
      </TransportProvider>
    </HelmetProvider>
  );
}

// Render the app
const rootElement = document.getElementById('root');
if (rootElement) {
  ReactDOM.createRoot(rootElement).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
