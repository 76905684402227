import { useMemo } from 'react';
import { IconRefresh } from '@tabler/icons-react';
import { MantineReactTable, MRT_RowData, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_FR } from 'mantine-react-table/locales/fr/index.cjs';
import { Box, Button, Flex, LoadingOverlay, Text } from '@mantine/core';
import { DataTableProps } from './types.ts';
import { useDataTableState } from './use-data-table-state.tsx';

export function DataTable<TData extends MRT_RowData, TResponse = unknown, TError = unknown>({
  columns,
  useQueryHook,
  queryParams = {},
  customActions,
  customRowActions,
  enableCustomRowActions = false,
  enableFilterModes = false,
  responseAdapter,
  errorAdapter = (error: TError) => error as Error,
}: DataTableProps<TData, TResponse, TError>) {
  const memoizedColumns = useMemo(() => columns, [columns]);

  const tableState = useDataTableState<TData>(memoizedColumns);
  const {
    columnFilters,
    columnFilterFns,
    globalFilter,
    sorting,
    pagination,
    setColumnFilters,
    setColumnFilterFns,
    setGlobalFilter,
    setSorting,
    setPagination,
  } = tableState;

  const {
    data: responseData,
    isLoading,
    error,
    isError,
    isFetching,
    refetch,
    isPlaceholderData,
  } = useQueryHook({
    ...queryParams,
    input: {
      pageSize: pagination.pageSize,
      page: pagination.pageIndex + 1,
      // Add any other filtering parameters later
      ...(queryParams?.input as object),
    },
  });

  const adaptedData = responseAdapter(responseData);
  const adaptedError = error ? errorAdapter(error) : null;

  const table = useMantineReactTable({
    columns: memoizedColumns,
    data: adaptedData.items,
    enableColumnFilterModes: enableFilterModes,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: enableCustomRowActions,
    renderRowActions: customRowActions,
    localization: MRT_Localization_FR,
    positionActionsColumn: 'last',
    memoMode: 'cells',
    mantineToolbarAlertBannerProps: isError
      ? {
          color: 'red',
          children: adaptedError?.message || 'Error loading data',
        }
      : undefined,
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableDensityToggle: false,
    mantineProgressProps: ({ isTopToolbar }) => ({
      style: { display: isTopToolbar ? 'block' : 'none' },
      value: 100,
    }),
    renderTopToolbarCustomActions: () => (
      <Flex align="center" gap="sm">
        <Button variant="subtle" leftSection={<IconRefresh />} onClick={() => refetch()}>
          Rafraîchir
        </Button>
        {customActions}
      </Flex>
    ),
    rowCount: adaptedData.total,
    initialState: {
      density: 'xs',
    },
    state: {
      columnFilterFns,
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      showLoadingOverlay: isFetching && isPlaceholderData,
      showSkeletons: isLoading,
      sorting,
    },
  });

  const loadingOverlay = useMemo(() => <LoadingOverlay visible={isLoading} />, [isLoading]);

  const noDataText = useMemo(() => <Text>No data available</Text>, []);

  if (error) {
    return (
      <Box>
        <Text>Error loading data: {adaptedError?.message}</Text>
      </Box>
    );
  }

  return (
    <Box>
      {loadingOverlay}
      {adaptedData.items ? <MantineReactTable table={table} /> : noDataText}
    </Box>
  );
}
