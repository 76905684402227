import { useCallback, useMemo, useState } from 'react';
import {
  Level,
  ListLevelsResponse,
} from '@buf/khiman_class-lib.bufbuild_es/proto/level/v1/level_pb';
import { DataTable } from '@components/data-table/data-table';
import { RowActions } from '@components/data-table/row-actions';
import { RowActionProps } from '@components/data-table/types';
import { ConnectError } from '@connectrpc/connect';
import { useListLevels } from '@hooks/services/level.tsx';
import { LevelCreateDrawer } from '@pages/admin/levels/components/level-create-drawer.tsx';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useDisclosure } from '@mantine/hooks';
import { LevelEditDrawer } from './components/level-edit-drawer.tsx';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

export const LevelsPage = () => {
  const [selectedLevelId, setSelectedLevel] = useState<string | undefined>();
  const [editLevelDrawerOpen, { open: openEditLevelDrawer, close: closeEditLevelDrawer }] =
    useDisclosure(false);
  const [createLevelDrawerOpen, { open: openCreateLevelDrawer, close: closeCreateLevelDrawer }] =
    useDisclosure(false);

  const handleEdit = useCallback(
    (levelId: string) => {
      if (levelId) {
        setSelectedLevel(levelId);
        openEditLevelDrawer();
      }
    },
    [openEditLevelDrawer]
  );

  const customTableActions = (
    <Button leftSection={<IconPlus />} onClick={openCreateLevelDrawer}>
      Ajouter un niveau
    </Button>
  );


  const handleDelete = useCallback(async (levelId: string) => {
    // Implement delete functionality here
    console.log('Delete level:', levelId);
  }, []);

  const columns = useMemo<MRT_ColumnDef<Level>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Nom',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ],
    []
  );

  const queryParams = useMemo(() => ({}), []);

  const customRowActions = useCallback(
    (props: RowActionProps<Level>) => (
      <RowActions<Level> {...props} onEdit={handleEdit} onDelete={handleDelete} />
    ),
    [handleEdit, handleDelete]
  );

  const responseAdapter = useCallback(
    (response: ListLevelsResponse | undefined) => ({
      items: response?.levels ?? [],
      total: response?.total ?? 0,
    }),
    []
  );

  return (
    <>
      <LevelEditDrawer
        opened={editLevelDrawerOpen}
        onClose={closeEditLevelDrawer}
        levelId={selectedLevelId}
      />
      <LevelCreateDrawer opened={createLevelDrawerOpen} onClose={closeCreateLevelDrawer} />
      <DataTable<Level, ListLevelsResponse, ConnectError>
        columns={columns}
        useQueryHook={useListLevels}
        customActions={customTableActions}
        queryParams={queryParams}
        enableFilterModes={false}
        enableCustomRowActions={true}
        customRowActions={customRowActions}
        responseAdapter={responseAdapter}
        errorAdapter={(error) => new Error(error.message)}
      />
    </>
  );
};
