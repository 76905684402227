import { ElementType } from 'react';
import { UserRole } from '@buf/khiman_class-lib.bufbuild_es/proto/user/v1/user_pb';
import {
  IconBubbleText,
  IconCalendarEvent,
  IconCertificate,
  IconFileInvoice,
  IconMath,
  IconSearch,
  IconSettings,
  IconUser,
} from '@tabler/icons-react';

interface MenuItem {
  header: string;
  section: {
    name: string;
    href: string;
    icon: ElementType;
    dropdownItems?: {
      name: string;
      href: string;
      badge?: string;
    }[];
  }[];
  roles: UserRole[];
}

export const menu: MenuItem[] = [
  {
    header: 'Professeur',
    section: [
      {
        name: 'Mes cours',
        href: '/app/teacher/courses',
        icon: IconCalendarEvent,
      },
      {
        name: 'Facturation',
        href: '/app/teacher/billing',
        icon: IconFileInvoice,
      },
    ],
    roles: [UserRole.TEACHER, UserRole.ADMIN],
  },
  {
    header: 'Élève',
    section: [
      {
        name: 'Rechercher un cours',
        href: '/app/student/courses/search',
        icon: IconSearch,
      },
      {
        name: 'Mes cours',
        href: '/app/student/courses/registered',
        icon: IconCalendarEvent,
      },
    ],
    roles: [UserRole.STUDENT, UserRole.ADMIN],
  },
  {
    header: 'Administration',
    section: [
      {
        name: 'Utilisateurs',
        href: '/app/admin/users',
        icon: IconUser,
      },
      {
        name: 'Cours',
        href: '/app/admin/courses',
        icon: IconCalendarEvent,
      },
      {
        name: 'Niveaux',
        href: '/app/admin/levels',
        icon: IconCertificate,
      },
      {
        name: 'Sujets',
        href: '/app/admin/subjects',
        icon: IconMath,
      },
      {
        name: 'Thèmes',
        href: '/app/admin/themes',
        icon: IconBubbleText,
      },
      {
        name: 'Facturation',
        href: '/app/admin/billing',
        icon: IconFileInvoice,
      },
      {
        name: 'Paramétrage',
        href: '/app/admin/settings',
        icon: IconSettings,
      },
    ],
    roles: [UserRole.ADMIN],
  },
  {
    header: 'Paramètres',
    section: [
      {
        name: 'Mon compte',
        href: '/app/profile',
        icon: IconUser,
      },
    ],
    roles: [UserRole.STUDENT, UserRole.TEACHER, UserRole.ADMIN],
  },
];
