import { useCallback, useMemo } from 'react';
import { CreateSubjectRequestSchema } from '@api/dtos/subject';
import { useListLevels } from '@hooks/services/level.tsx';
import { useCreateSubject } from '@hooks/services/subject';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { Button, Drawer, Fieldset, Flex, Select, TextInput } from '@mantine/core';

interface SubjectDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const SubjectCreateDrawer = (props: SubjectDrawerProps) => {
  const { opened, onClose } = props;
  const mutateSubject = useCreateSubject();

  const defaultValues = {
    name: '',
    description: '',
    levelId: '',
  };

  const { data: levelsData } = useListLevels({
    input: {},
  });

  // Here we memoize the levels options to avoid re-rendering the component since this is unlikely to change often
  const levelOptions = useMemo(
    () =>
      levelsData?.levels?.map((level) => ({
        value: level.id,
        label: level.name,
      })) ?? [],
    [levelsData?.levels]
  );

  const { Field, handleSubmit, reset, Subscribe } = useForm({
    validatorAdapter: zodValidator(),
    defaultValues,
    validators: {
      onSubmit: CreateSubjectRequestSchema,
    },
    onSubmit: async ({ value }) => {
      console.log('Submit:', value);
      mutateSubject.mutate(value, {
        onSuccess: () => {
          handleClose();
        },
      });
    },
  });

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <Drawer offset={8} radius="md" opened={opened} onClose={handleClose} position="right">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Flex justify="left" direction="column" gap="md">
          <Fieldset legend="Niveau" variant="filled" w="100%">
            <Field
              name="name"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Nom"
                  placeholder="Nom"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="description"
              children={({ state, handleChange, handleBlur }) => (
                <TextInput
                  value={state.value ?? ''}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label="Description"
                  placeholder="Description"
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                />
              )}
            />
            <Field
              name="levelId"
              children={({ state, handleChange, handleBlur }) => (
                <Select
                  value={state.value.toString() ?? ''}
                  onChange={(value) => handleChange(value as string)}
                  onBlur={handleBlur}
                  label="Niveau"
                  placeholder="Sélectionner un niveau"
                  data={levelOptions}
                  error={state.meta.errors ? state.meta.errors.join(',') : null}
                  searchable
                />
              )}
            />
          </Fieldset>
          <Subscribe
            selector={(state) => [state.isDirty]}
            children={([isDirty]) => (
              <Button
                disabled={!isDirty || mutateSubject.isPending}
                loading={mutateSubject.isPending}
                type="submit"
              >
                Créer
              </Button>
            )}
          />
        </Flex>
      </form>
    </Drawer>
  );
};
