import { jwtDecode } from 'jwt-decode';

interface JWTPayload {
  exp: number;

  [key: string]: string | number;
}

export const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = jwtDecode<JWTPayload>(token);
    return decoded.exp * 1000 < Date.now();
  } catch {
    return true;
  }
};

export const isTokenExpiringSoon = (token: string, thresholdMinutes: number = 5): boolean => {
  try {
    const decoded = jwtDecode<JWTPayload>(token);
    const expirationTime = decoded.exp * 1000;
    const thresholdMs = thresholdMinutes * 60 * 1000;
    return expirationTime < Date.now() + thresholdMs;
  } catch {
    return true;
  }
};

export const getTokenExpirationDate = (token: string): Date | null => {
  try {
    const decoded = jwtDecode<JWTPayload>(token);
    return new Date(decoded.exp * 1000);
  } catch {
    return null;
  }
};
