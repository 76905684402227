import { memo } from 'react';
import classes from './navbar.module.scss';
import { useMe } from '@hooks/services/auth.tsx';
import { Link } from '@tanstack/react-router';
import { NavLink, Stack, Title } from '@mantine/core';
import { menu } from './menu-sections';
import { useUser } from '@hooks/services/user.tsx';

function NavbarMain() {
  const userId = useMe();
  const { data, isError } = useUser({ input: { id: userId } });

  if (isError) {
    // TODO: handle auth error
    return null;
  }

  const role = data?.user?.role;
  if (!role) return null;

  return (
    <Stack gap="xl" w="100%">
      {menu.map(
        (item) =>
          item.roles.includes(role) && (
            <div key={item.header}>
              <Title order={6} className={classes.sectionTitle}>
                {item.header}
              </Title>

              {item.section.map((subItem) =>
                subItem.dropdownItems ? (
                  <NavLink
                    variant="subtle"
                    key={subItem.name}
                    label={subItem.name}
                    childrenOffset={0}
                    className={classes.sectionLink}
                    leftSection={subItem.icon && <subItem.icon />}
                  >
                    {subItem.dropdownItems?.map((dropdownItem) => (
                      <NavLink
                        variant="subtle"
                        component={Link}
                        to={dropdownItem.href}
                        key={dropdownItem.name}
                        label={dropdownItem.name}
                        className={classes.sectionDropdownItemLink}
                        leftSection={<span className="dot" />}
                      />
                    ))}
                  </NavLink>
                ) : (
                  <NavLink
                    variant="subtle"
                    component={Link}
                    to={subItem.href}
                    key={subItem.name}
                    label={subItem.name}
                    className={classes.sectionLink}
                    leftSection={subItem.icon && <subItem.icon />}
                  />
                )
              )}
            </div>
          )
      )}
    </Stack>
  );
}

export default memo(NavbarMain);
